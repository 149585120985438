import { classNameJoin } from "utils/lib";
import Progress from "./Progress";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  small?: boolean;
  frame?: boolean;
  ghost?: boolean;
  loading?: boolean;
}

const Button = ({
  children,
  className,
  disabled,
  frame,
  ghost,
  loading,
  small = false,
  ...props
}: React.PropsWithChildren<IProps>) => {
  return (
    <button
      {...props}
      className={classNameJoin([
        "whitespace-nowrap inline-block rounded-lg border select-none transition-all disabled:bg-secondary-900 disabled:text-secondary-500 disabled:border-secondary-900",
        small ? "h-9 px-4 text-sm" : "h-10 px-5",
        ghost
          ? "bg-white text-secondary-500 border-secondary-500"
          : frame
          ? "border-accect text-accect bg-white"
          : "border-accect bg-accect text-white hover:bg-primary-400",
        className,
      ])}
      disabled={disabled || loading}
    >
      {loading ? <Progress size={24} color="text-secondary-400" /> : children}
    </button>
  );
};

export default Button;
