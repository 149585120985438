import axios from "axios";
import env from "config/env";

const socket = axios.create({
  baseURL: env.SOCKET_URL,
});

export const setSocketHeader = (token: string) => {
  socket.defaults.headers.common["Authorization"] = token;
};

export default socket;
