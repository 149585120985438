import { classNameJoin } from "utils/lib";
import { Image } from "lucide-react";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onUpload: (files: FileList | null) => void;
}

const Button = ({ onUpload, className, ...props }: IProps) => {
  const uploadHandler = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    onUpload(ev.target.files);
    ev.target.value = "";
  };

  return (
    <label
      className={classNameJoin([
        "flex items-center justify-center w-14 h-14 cursor-pointer border rounded-[10px] border-secondary-700 text-secondary-700",
        className,
      ])}
    >
      <Image size={16} />
      <input
        className="hidden"
        type="file"
        onChange={uploadHandler}
        {...props}
      />
    </label>
  );
};

export default Button;
