import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faArrowUp,
  faArrowRightFromBracket,
  faBars,
  faBox,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faFilter,
  faGauge,
  faHeart,
  faHouse,
  faImage,
  faList,
  faLock,
  faMoneyBillWave,
  faPlay,
  faPlus,
  faCartShopping,
  faReply,
  faSearch,
  faUser,
  faWifi,
  faXmark,
  faArrowRightArrowLeft,
  faExclamationCircle,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

import {
  faBell,
  faCalendar,
  faCalendarDays,
  faComment,
  faCommentDots,
  faCreditCard,
  faEdit,
  faFileLines,
  faGem,
  faHeart as farHeart,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faArrowUp,
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faExclamationCircle,
  faBars,
  faBell,
  faBox,
  faCalendar,
  faCalendarDays,
  faCaretDown,
  faCaretUp,
  faCartShopping,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
  faComment,
  faCommentDots,
  faCreditCard,
  faEdit,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faFileLines,
  faFilter,
  faGauge,
  faGem,
  faHeart,
  faHouse,
  faImage,
  faList,
  faLock,
  faMoneyBillWave,
  faPenToSquare,
  faPlay,
  faPlus,
  faReply,
  farHeart,
  faSearch,
  faTrashAlt,
  faUser,
  faWifi,
  faXmark
);
