import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { classNameJoin } from "utils/lib";

export interface IConfirm {
  title: string;
  content: string;
  options?: {
    hideCancel?: boolean;
    backgroundCancel?: boolean;
    cancelText?: string;
    confirmText?: string;
  };
  onConfirm?: () => void;
  onCancel?: () => void;
}

export interface IConfirmContext {
  show: (props: IConfirm) => void;
  ok: (content: string, onConfirm?: () => void) => void;
  error: (content: string, onConfirm?: () => void) => void;
}

export const ConfirmContext = React.createContext<IConfirmContext>(null!);

const ConfirmProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const timerRef = useRef<NodeJS.Timeout | null>();
  const [toggle, setToggle] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<IConfirm>({
    title: "",
    content: "",
    onConfirm: () => {},
  });

  const cancelHandler = () => {
    setToggle(false);

    timerRef.current = setTimeout(() => {
      setConfirm({
        title: "",
        content: "",
        options: {
          hideCancel: false,
          backgroundCancel: true,
          cancelText: "취소",
          confirmText: "확인",
        },
        onConfirm: () => {},
        onCancel: () => {},
      });

      confirm.onCancel && confirm.onCancel();
    }, 300);
  };

  const bgCancelHandler = () => {
    if (!confirm.options?.hideCancel) {
      if (confirm.options?.backgroundCancel) {
        cancelHandler();
      }
    }
  };

  const confirmHandler = () => {
    const { onConfirm } = confirm;
    onConfirm && onConfirm();
    setToggle(false);
  };

  const show = useCallback(({ options, ...props }: IConfirm) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setConfirm((prevState) => {
      return {
        ...prevState,
        ...props,
        options: {
          hideCancel: false,
          backgroundCancel: true,
          cancelText: "취소",
          confirmText: "확인",
          ...options,
        },
        onConfirm: props.onConfirm && props.onConfirm,
        onCancel: props.onCancel && props.onCancel,
      };
    });

    setToggle(true);
  }, []);

  const ok = useCallback(
    (content: string, onConfirm?: () => void) => {
      show({
        title: "알림",
        content,
        onConfirm,
        options: {
          cancelText: "",
          confirmText: "",
          hideCancel: true,
          backgroundCancel: false,
        },
      });
    },
    [show]
  );

  const error = useCallback(
    (content: string, onConfirm?: () => void) => {
      show({
        title: "오류발생",
        content,
        onConfirm,
        options: {
          cancelText: "",
          confirmText: "",
          hideCancel: true,
          backgroundCancel: false,
        },
      });
    },
    [show]
  );

  const value = useMemo(() => ({ show, ok, error }), [show, ok, error]);

  return (
    <ConfirmContext.Provider value={value}>
      {children}
      <Wrapper toggle={toggle}>
        <div
          className="absolute left-0 right-0 bottom-0 top-0 bg-black opacity-70"
          onClick={bgCancelHandler}
        />
        <div
          className={classNameJoin([
            "absolute top-[50%] left-[50%] translate-x-[-50%] z-40 flex flex-col items-center min-w-[340px] rounded-lg overflow-hidden transition-all duration-300 select-none bg-white",
            toggle
              ? "opacity-100 visible translate-y-[-50%]"
              : "opacity-0 invisible translate-y-[-40%]",
          ])}
        >
          <div className="w-full h-[36px]">
            {!confirm.options?.hideCancel && (
              <button
                className="float-right w-[36px] h-[36px] text-xl mt-2 mr-2"
                onClick={cancelHandler}
              >
                &#10005;
              </button>
            )}
          </div>
          <div className="w-full flex-1 pb-8 text-center">
            <div className="pb-2 font-semibold">{confirm.title}</div>
            <p
              className="pt-2 px-4 text-[15px] leading-2"
              dangerouslySetInnerHTML={{ __html: confirm.content }}
            />
          </div>
          <div className="flex w-full h-[48px] items-center">
            {!confirm.options?.hideCancel && (
              <button
                className="flex-1 h-full bg-secondary-900"
                onClick={cancelHandler}
              >
                {confirm.options?.cancelText || "취소"}
              </button>
            )}
            <button
              className="flex-1 h-full bg-accect text-white"
              onClick={confirmHandler}
            >
              {confirm.options?.confirmText || "확인"}
            </button>
          </div>
        </div>
      </Wrapper>
    </ConfirmContext.Provider>
  );
};

const Wrapper = styled.div<{ toggle: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.3s;
  z-index: 1000;
  visibility: ${(props) => (props.toggle ? "visible" : "hidden")};
  opacity: ${(props) => (props.toggle ? 1 : 0)};
`;

export default ConfirmProvider;
