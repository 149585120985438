import { icons } from "lucide-react";

export type IconName = keyof typeof icons;

interface Props {
  className?: string;
  name: IconName;
  size?: number;
  color?: string;
}

export default function LucideIcon({ className, color, name, size }: Props) {
  const LucideIcon = icons[name];

  return <LucideIcon className={className} color={color} size={size} />;
}
