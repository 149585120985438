import { useContext } from "react";
import { classNameJoin } from "utils/lib";
import { ModalContext } from "./Body";

interface IProps {
  className?: string;
  isClose?: boolean;
}

const Header = ({
  children,
  className,
  isClose = true,
}: React.PropsWithChildren<IProps>) => {
  const modal = useContext(ModalContext);

  const onClose = () => {
    modal.close();
  };

  return (
    <div className="flex items-center w-full justify-between">
      <h5 className={classNameJoin([className])}>{children}</h5>

      {isClose ? (
        <button
          className="float-right w-[36px] h-[36px] text-xl"
          onClick={onClose}
        >
          &#10005;
        </button>
      ) : null}
    </div>
  );
};

export default Header;
