import React, { useState } from "react";
import styled from "styled-components";
import { classNameJoin } from "utils/lib";

interface IProps {
  title: string;
  message: string;
  onConfirm?: () => void;
}

export interface IAlertContext {
  show: (props: IProps) => void;
}

export const AlertContext = React.createContext<IAlertContext>(null!);

const AlertProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const [props, setProps] = useState<IProps>({
    title: "",
    message: "",
    onConfirm: () => {},
  });

  const show = ({ title, message, onConfirm }: IProps) => {
    setToggle(true);
    setProps({
      title,
      message,
      onConfirm,
    });
  };

  const confirmHandler = () => {
    setToggle(false);
    props.onConfirm && props.onConfirm();
  };

  const value = {
    show,
  };

  return (
    <AlertContext.Provider value={value}>
      {children}
      <Wrapper toggle={toggle}>
        <div className="absolute left-0 right-0 bottom-0 top-0 bg-black opacity-70" />
        <div
          className={classNameJoin([
            "select-none absolute flex flex-col items-center top-[50%] left-[50%] translate-x-[-50%] z-40 min-w-[340px] rounded-md overflow-hidden transition-all duration-300 p-4 bg-white",
            toggle
              ? "opacity-100 visible translate-y-[-50%]"
              : "opacity-0 invisible translate-y-[-40%]",
          ])}
        >
          <div className="w-full">
            <h6 className="text-left">{props.title}</h6>
            <p
              className="py-4 text-[15px] text-left select-text"
              dangerouslySetInnerHTML={{ __html: props.message }}
            />
          </div>
          <div className="self-end">
            <button
              className="bg-white py-1.5 px-4 text-sm rounded-lg"
              onClick={confirmHandler}
            >
              확인
            </button>
          </div>
        </div>
      </Wrapper>
    </AlertContext.Provider>
  );
};

const Wrapper = styled.div<{ toggle: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.3s;
  z-index: 1000;
  visibility: ${(props) => (props.toggle ? "visible" : "hidden")};
  opacity: ${(props) => (props.toggle ? 1 : 0)};
`;

export default AlertProvider;
