import { useState, useEffect } from "react"; // React의 상태 및 사이드 이펙트를 처리하기 위한 훅
import { mobileOS } from "utils/lib"; // 디바이스의 OS를 확인하기 위한 유틸 함수
import { useLocation } from "react-router-dom";
import { STORAGE_KEY } from "config/const";
import { useRecoilState } from "recoil";
import { PWAInstallOpenState } from "store/modal";

let deferredPromptCache: any = null; // 전역 변수로 캐시된 프롬프트 관리

const PWAInstallModal = () => {
  const [isPWAInstallPopupOpen, setIsPWAInstallPopupOpen] =
    useRecoilState(PWAInstallOpenState);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null); // 프롬프트 상태
  const os = mobileOS();
  const isPWA = window.matchMedia("(display-mode: standalone)").matches;
  const location = useLocation(); // 현재 경로 정보를 가져옴

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isInApp =
      /KAKAOTALK/i.test(userAgent) || /Instagram/i.test(userAgent);

    // 인앱일 경우 모달 표시하지 않음
    if (isInApp) return;

    //  ios인 경우 바로 표시
    if (os === "I") {
      setIsPWAInstallPopupOpen(true); // iOS의 경우 팝업 항상 열기
    } else if (os === "A") {
      if (deferredPromptCache) {
        // 캐시된 프롬프트가 있으면 바로 사용
        setDeferredPrompt(deferredPromptCache);
        setIsPWAInstallPopupOpen(true);
      }

      // 새롭게 beforeinstallprompt 이벤트 등록
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        deferredPromptCache = e; // 전역 변수에 이벤트 캐싱
        setDeferredPrompt(e); // 상태에 이벤트 저장
        setIsPWAInstallPopupOpen(true); // 팝업 열기
        // pwaStorageKey를 삭제하여, 안드로이드에서 PWA 설치 이후 최초 접속시 브라우저와 데이터가 연동되지 않는 상태에서 시작하도록 설정
        localStorage.removeItem(STORAGE_KEY.pwaStorageKey);
      });
    }

    return () => {
      window.removeEventListener("beforeinstallprompt", () => {});
    };
  }, [os, location.pathname]);

  if (
    !os ||
    isPWA ||
    !isPWAInstallPopupOpen ||
    location.pathname !== "/broadcast"
  )
    return <></>;

  // pwa 다운로드 모달 닫기 핸들러
  const modalCloseHandler = () => {
    setIsPWAInstallPopupOpen(false);
  };

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // PWA 설치 프롬프트 표시
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`사용자가 PWA 설치 선택: ${outcome}`);

      deferredPromptCache = null; // 캐시 초기화
      setDeferredPrompt(null); // 상태 초기화
      setIsPWAInstallPopupOpen(false); // 모달 닫기
    }
  };

  return (
    <div className="fixed w-[100svw] h-[100svh] top-0 left-0 z-[1000] flex flex-col justify-end bg-[rgba(165,165,165,0.4)]">
      {/* 모달 내용 */}
      <div className="bg-white p-[20px] pwa-install-modal-content relative rounded-tl-[20px] rounded-tr-[20px] flex flex-col items-center gap-[20px]">
        {/* 모달 닫기 버튼 */}
        <button
          onClick={modalCloseHandler}
          className="absolute top-[5px] right-[10px] w-[25px] h-[25px] 
          flex items-center justify-center
          font-bold bg-none border-none cursor-pointer text-[20px] 
         text-gray-500 p-0"
        >
          &times;
        </button>

        {/* 모달 설명 텍스트 */}
        <div className="mt-3 text-center text-[15px] font-bold">
          라이브 예약부터 문의톡 확인까지! <br /> 이제 앱에서 빠르게 관리해
          보세요.
        </div>

        <div className="w-full">
          {os && (
            <img
              src={`/img_${
                os === "I"
                  ? "guide_install_pwa_ios"
                  : "pwa_push_notification_seller"
              }.png`}
              alt=""
            />
          )}

          {/* Android용 설치 버튼 */}
          {os === "A" && (
            <button
              className="w-full whitespace-nowrap select-none inline-block mt-5 rounded-md border transition-all duration-300 disabled:bg-secondary-900 disabled:text-secondary-500 disabled:border-secondary-900 h-[40px] px-5 border-primary-900 bg-primary-900 text-white hover:bg-primary-800"
              onClick={handleInstallClick}
            >
              홈 화면에 추가
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PWAInstallModal;
