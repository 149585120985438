import React, { useEffect } from "react";
import { classNameJoin } from "utils/lib";

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  autoResize?: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, IProps>(
  (
    {
      className,
      style,
      autoResize = true,
      minHeight = 100,
      maxHeight = 300,
      onChange,
      ...props
    },
    ref
  ) => {
    const changeHandler = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (autoResize) {
        ev.target.style.setProperty("height", "inherit");
        const { scrollHeight } = ev.target;
        ev.target.style.setProperty("height", scrollHeight + "px");
      }

      onChange && onChange(ev);
    };

    useEffect(() => {
      if ((ref as any) && minHeight) {
        (ref as any).current.style.setProperty("height", minHeight + "px");
      }
    }, [minHeight, ref]);

    return (
      <textarea
        {...props}
        ref={ref}
        style={{ minHeight, maxHeight, ...style }}
        className={classNameJoin([
          "resize-none rounded-md outline-none w-full bg-transparent p-3 border transition text-sm border-secondary-700",
          className,
        ])}
        onChange={changeHandler}
      />
    );
  }
);

export default Textarea;
