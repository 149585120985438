import { IDetailOrderCount, Status, TabType } from "interfaces/live.interface";

export const TAB_KEYS: {
  name: string;
  type: TabType;
  people: keyof IDetailOrderCount;
  count: keyof IDetailOrderCount;
}[] = [
  {
    name: "미입금",
    type: "ORDER",
    people: "orderPlacedPeopleCount",
    count: "orderPlacedCount",
  },
  {
    name: "입금확인",
    type: "DEPOSIT",
    people: "paymentConfirmedPeopleCount",
    count: "paymentConfirmedCount",
  },
  {
    name: "배송준비",
    type: "DELIVERY_READY",
    people: "deliveryReadyPeopleCount",
    count: "deliveryReadyCompletedCount",
  },
  {
    name: "배송완료",
    type: "DELIVERY",
    people: "deliveryCompletedPeopleCount",
    count: "deliveryCompletedCount",
  },
  {
    name: "주문취소",
    type: "CANCEL",
    people: "orderCancelledPeopleCount",
    count: "orderCancelledCount",
  },
  {
    name: "환불처리",
    type: "REFUND",
    people: "orderRefundedPeopleCount",
    count: "orderRefundedCount",
  },
  {
    name: "전체",
    type: "ALL",
    people: "totalPeopleCount",
    count: "totalOrderCount",
  },
];

export const STATUS_BUTTONS: {
  name: string;
  status: Status;
}[] = [
  {
    name: "미입금",
    status: "ORDER",
  },
  {
    name: "입금확인",
    status: "DEPOSIT",
  },
  {
    name: "배송준비",
    status: "DELIVERY_READY",
  },
  {
    name: "배송완료",
    status: "DELIVERY",
  },
  {
    name: "주문취소",
    status: "CANCEL",
  },
  {
    name: "환불처리",
    status: "REFUND",
  },
];

// 비활성화 상태
export const DISABLED: { [key in TabType]: Status[] } = {
  ORDER: ["ORDER"],
  DEPOSIT: ["DEPOSIT"],
  DELIVERY_READY: ["ORDER", "DELIVERY_READY"],
  DELIVERY: ["ORDER", "DELIVERY"],
  CANCEL: ["ORDER", "CANCEL"],
  REFUND: ["ORDER", "REFUND"],
  ALL: ["CANCEL", "DELIVERY", "DELIVERY_READY", "DEPOSIT", "ORDER", "REFUND"],
};

export const EXCEL_NAME: { [key in TabType]: string } = {
  ORDER: "주문내역 목록.xlsx",
  DEPOSIT: "입금확인 목록.xlsx",
  DELIVERY_READY: "배송준비 목록.xlsx",
  DELIVERY: "배송완료 목록.xlsx",
  CANCEL: "",
  REFUND: "",
  ALL: "전체 목록.xlsx",
};

export const EXCEL_COLUMN = {
  nickname: "주문자명",
  productName: "상품명",
  orderCode: "주문코드",
  number: "상품번호",
  productOption: "옵션",
  price: "단가",
  count: "수량",
  totalPrice: "총 금액",
  totalPayment: "총 결제액",
  phone: "주문자 연락처",
  name: "수령인(입금자명)",
  shippingPhone: "수령인 연락처",
  address: "수령인 주소",
  memo: "문의사항",
  memo2: "메모",
};

export const STATUS_MAP: { [key: string]: string } = {
  ORDER: "미입금",
  CANCEL: "주문취소",
  REFUND: "환불처리",
  DEPOSIT: "입금확인",
  DELIVERY: "배송완료",
  DELIVERY_READY: "배송준비",
};

export const ALL_TYPE = [
  "ORDER",
  "CANCEL",
  "REFUND",
  "DEPOSIT",
  "DELIVERY",
  "CANCEL_REQUEST",
  "DELIVERY_READY",
].join(", ");
