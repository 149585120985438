import { classNameJoin } from "utils/lib";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  frame?: boolean;
}

const Button = ({
  children,
  className,
  frame,
  ...props
}: React.PropsWithChildren<IProps>) => {
  return (
    <button
      className={classNameJoin([
        "text-sm py-0.5 px-2.5 whitespace-nowrap rounded-2xl border border-accect disabled:bg-secondary-900 disabled:text-secondary-500 disabled:border-secondary-900 transition-all duration-300",
        frame ? "bg-transparent text-accect" : "bg-accect text-white",
        className,
      ])}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
