import styled from "styled-components";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Switch = ({ className, ...props }: IProps) => {
  return (
    <Wrapper>
      <input {...props} type="checkbox" />
      <Box>
        <div className="ui-switch-ball" />
      </Box>
    </Wrapper>
  );
};

const Box = styled.div`
  position: relative;
  width: 40px;
  height: 24px;
  display: inline-flex;
  border-radius: 12px;
  background-color: #cccccc;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s;

  .ui-switch-ball {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 11px;
    transition: all 0.3s;
  }
`;

const Wrapper = styled.label`
  display: inline-flex;
  input[type="checkbox"] {
    display: none;

    &:checked {
      & + ${Box} {
        background-color: #ed3c80;

        & > .ui-switch-ball {
          left: calc(100% - 22px);
        }
      }
    }
  }
`;
export default Switch;
