import React from "react";
import styled, { css } from "styled-components";

interface IProps extends IStyleProps {
  isBackgroundClose?: boolean;
  onClose: () => void;
}

interface IStyleProps {
  toggle: boolean;
}

interface IModalContext {
  close: () => void;
}

export const ModalContext = React.createContext<IModalContext>(null!);

const Body = ({
  children,
  toggle,
  isBackgroundClose = true,
  onClose,
}: React.PropsWithChildren<IProps>) => {
  const close = () => {
    onClose();
  };

  const value = {
    close,
  };

  return (
    <ModalContext.Provider value={value}>
      <Wrapper toggle={toggle}>
        <div
          className="absolute top-0 bottom-0 left-0 right-0 bg-black opacity-80"
          onClick={() => {
            if (isBackgroundClose) onClose();
          }}
        />
        <Content toggle={toggle}>{children}</Content>
      </Wrapper>
    </ModalContext.Provider>
  );
};

const Wrapper = styled.div<IStyleProps>`
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s;

  ${(props) =>
    props.toggle
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;

const Content = styled.div<IStyleProps>`
  display: inline-block;
  margin: auto;
  padding: 10px 16px 16px;
  max-height: 100%;
  min-width: auto;
  z-index: 40;
  background-color: white;
  border-radius: 4px;
  overflow: auto;
  transform: translateY(20px);
  transition: all 0.3s;

  ${(props) =>
    props.toggle
      ? css`
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        `
      : css`
          opacity: 0;
          visibility: hidden;
          transform: translateY(50px);
        `}

  @media (min-width: 460px) {
    padding: 16px 20px 20px;
  }
`;

export default Body;
