import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { useMyPoint } from "utils/helper";
import { userState } from "store/user";
import Notification from "components/Notification";
import { Icon, Thumbnail } from "components/shared";
import SymbolIcon from "../assets/symbol_icon.png";
import SymbolText from "../assets/symbol_text.png";
import { Menu, X } from "lucide-react";

interface IProps {
  toggle: boolean;
  onToggle: () => void;
}

const GlobalHeader = ({ toggle, onToggle }: IProps) => {
  const user = useRecoilValue(userState);
  const { commaPoint, refetch } = useMyPoint();
  const location = useLocation();
  const navigate = useNavigate();

  const { name, profileUrl } = useMemo(() => {
    if (!user) {
      return {
        name: null,
        profileUrl: null,
      };
    }

    return {
      name: user.name,
      profileUrl: user.profileUrl,
      uuid: user.uuid,
    };
  }, [user]);

  useEffect(() => {
    refetch();
  }, [location, refetch]);

  return (
    <Header className="border-b border-secondary-900 select-none">
      <Hamberger onClick={onToggle}>
        <span className="bars">{!toggle ? <Menu /> : <X />}</span>
      </Hamberger>
      <Title>
        <div className="flex items-center">
          <img src={SymbolIcon} width={36} alt="symbol icon" />
          <div className="ml-2">
            <img src={SymbolText} width={86} alt="symbol text" />
            <div className="text-[11px] -mt-1.5">셀러 관리자</div>
          </div>
        </div>
      </Title>
      <Tools className="space-x-4">
        <div className="font-bold text-accect hidden md:inline-block">
          <span>{commaPoint}</span>
          <span className="ml-1">P</span>
        </div>
        {/* <Notification user={user} /> */}
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate("/profile")}
        >
          <Thumbnail size={32} src={profileUrl} />
          <span className="user-name ml-3 text-sm">{name}</span>
        </div>
      </Tools>
    </Header>
  );
};

const Hamberger = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  .bars {
    display: block;

    @media (min-width: 1024px) {
      display: none;
    }
  }
`;

const Header = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-right: 16px;
  z-index: 999;
  background-color: white;

  @media (min-width: 1024px) {
    padding: 0 16px;
  }
`;

const Title = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    transform: translate(0, -50%);
    flex-direction: row;
    align-items: end;
    left: 24px;
  }
`;

const SubTitle = styled.span`
  font-size: 12px;
  margin-left: 0px;

  @media (min-width: 1024px) {
    font-size: 14px;
    display: block;
    margin-left: 12px;
  }
`;

const Tools = styled.div`
  display: flex;
  align-items: center;
  height: 50px;

  .user-name {
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }
`;

export default GlobalHeader;
