import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useState } from "react";
import { classNameJoin } from "utils/lib";
import Icon from "./Icon";
import LucideIcon from "./LucideIcon";

export interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  prefix?: IconProp;
  suffix?: IconProp;
  clear?: boolean;
  small?: boolean;
  inputClassName?: string;
  suffixClassName?: string;
  onSuffixClick?: (ev: React.MouseEvent<HTMLDivElement>) => void;
  onEnter?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  onClear?: () => void;
}

const Input = React.forwardRef<HTMLInputElement, IProps>(
  (
    {
      value,
      className,
      small,
      inputClassName,
      suffixClassName,
      suffix,
      prefix,
      clear,
      onFocus,
      onBlur,
      onKeyUp,
      onSuffixClick,
      onEnter,
      onClear,
      ...props
    },
    ref
  ) => {
    const [focus, setFocus] = useState<boolean>(false);

    // onFocus
    const focusHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
      ev.preventDefault();
      onFocus && onFocus(ev);
      setFocus(true);
    };

    // onBlur
    const blurHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
      ev.preventDefault();
      onBlur && onBlur(ev);
      setFocus(false);
    };

    const keyupHandler = (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key.toUpperCase() === "ENTER") {
        onEnter && onEnter(ev);
      }

      onKeyUp && onKeyUp(ev);
    };

    return (
      <label
        className={classNameJoin([
          "relative inline-flex items-center overflow-hidden transition-all bg-white",
          className,
          small ? "h-9" : "h-10",
        ])}
      >
        {prefix ? (
          <div
            className={classNameJoin([
              "absolute top-1/2 left-1 -translate-y-1/2 flex items-center justify-center cursor-pointer text-secondary-600",
              small ? "w-7 h-7" : "w-10 h-10",
              suffixClassName,
            ])}
            onClick={onSuffixClick}
          >
            <Icon icon={prefix} />
          </div>
        ) : null}

        <input
          ref={ref}
          className={classNameJoin([
            "w-full h-full rounded-lg font-normal border outline-secondary-300 disabled:bg-secondary-900 disabled:text-secondary-500",
            prefix ? (small ? "pl-8" : "pl-11") : "pl-3",
            suffix ? (small ? "pr-8" : "pr-11") : "pr-3",
            clear && value ? "pr-8" : "pr-3",
            small ? "text-xs" : "text-sm",
            inputClassName,
          ])}
          value={value}
          onFocus={focusHandler}
          onBlur={blurHandler}
          onKeyUp={keyupHandler}
          {...props}
        />

        {suffix ? (
          <div
            className={classNameJoin([
              "absolute top-1/2 right-1 -translate-y-1/2 flex items-center justify-center cursor-pointer text-secondary-600",
              small ? "w-7 h-7" : "w-10 h-10",
              suffixClassName,
            ])}
            onClick={onSuffixClick}
          >
            <Icon icon={suffix} />
          </div>
        ) : null}

        {clear && value ? (
          <button
            type="button"
            className={classNameJoin([
              "absolute top-1/2 right-1 -translate-y-1/2 flex items-center justify-center cursor-pointer text-secondary-600",
              small ? "w-7 h-7" : "w-10 h-10",
              suffixClassName,
            ])}
            onClick={onClear}
          >
            <LucideIcon name={`CircleX`} size={18} />
          </button>
        ) : null}
      </label>
    );
  }
);

export default Input;
